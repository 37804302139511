var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.$t("Adcionar cidade"),
          show: _vm.showSidebar,
          fetching: _vm.zipCodeLoading,
          saving: _vm.zipCodeLoading,
          width: "500px",
        },
        on: { save: _vm.addLocation, hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "deliveryLocationForm", attrs: { name: "form" } },
                      [
                        _c("e-city-search", {
                          attrs: {
                            id: "delivery-location-city",
                            name: "locationCity",
                            label: _vm.$t("Cidade"),
                            required: "",
                            "value-is-object": "",
                          },
                          model: {
                            value: _vm.cityData,
                            callback: function ($$v) {
                              _vm.cityData = $$v
                            },
                            expression: "cityData",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }