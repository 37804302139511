var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.sunday, "day-name": "sunday" },
      }),
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.monday, "day-name": "monday" },
      }),
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.tuesday, "day-name": "tuesday" },
      }),
      _c("opening-hour", {
        attrs: {
          day: _vm.store.openingHours.wednesday,
          "day-name": "wednesday",
        },
      }),
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.thursday, "day-name": "thursday" },
      }),
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.friday, "day-name": "friday" },
      }),
      _c("opening-hour", {
        attrs: { day: _vm.store.openingHours.saturday, "day-name": "saturday" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }