<template>
  <!-- A implementação não é a melhor, mas com v-for estávamos com problema de binding -->
  <div>
    <opening-hour
      :day="store.openingHours.sunday"
      day-name="sunday"
    />
    <opening-hour
      :day="store.openingHours.monday"
      day-name="monday"
    />
    <opening-hour
      :day="store.openingHours.tuesday"
      day-name="tuesday"
    />
    <opening-hour
      :day="store.openingHours.wednesday"
      day-name="wednesday"
    />
    <opening-hour
      :day="store.openingHours.thursday"
      day-name="thursday"
    />
    <opening-hour
      :day="store.openingHours.friday"
      day-name="friday"
    />
    <opening-hour
      :day="store.openingHours.saturday"
      day-name="saturday"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { days } from '@/utils/dateUtils'
import OpeningHour from './OpeningHour.vue'

export default {
  components: {
    OpeningHour,
  },

  computed: {
    ...mapState('pages/store/storeMaintain', ['store']),
  },

  mounted() {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const day in this.store.openingHours) {
      if (!this.store.openingHours[day]) {
        if (days.indexOf(day) >= 0) {
          this.store.openingHours[day] = {
            openingTime: null,
            closingTime: null,
            delivery: false,
            zeroRate: false,
          }
        }
      }
    }
  },
}
</script>

<style></style>
