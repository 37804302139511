<template>
  <div>
    <b-row>
      <b-col
        md="3"
        style="margin-top: 30px"
        class="text-right"
      >
        <FormulateInput
          id="day-label"
          type="label"
          :name="`hour_${dayName}Label`"
          :value="$t(`days.${dayName}`)"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`${dayName}-opening-time`"
          v-model="day.openingTime"
          :name="`hour_${dayName}OpeningTime`"
          type="text-mask"
          :mask="['##:##']"
          :label="$t('Abre')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`${dayName}-closing-time`"
          v-model="day.closingTime"
          :name="`hour_${dayName}ClosingTime`"
          type="text-mask"
          :mask="['##:##']"
          :label="$t('Fecha')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-model="day.delivery"
          type="switch"
          :name="`hour_${dayName}Delivery`"
          :label="$t('Delivery')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          v-model="day.zeroRate"
          type="switch"
          :name="`hour_${dayName}ZeroRate`"
          :label="$t('Taxa Zero')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },

  props: {
    dayName: {
      type: String,
      default: undefined,
    },
    day: {
      type: Object,
      default: () => ({
        day: null,
        openingTime: null,
        closingTime: null,
        delivery: null,
        zeroRate: null,
      }),
    },
  },

  data() {
    return {}
  },
}
</script>

<style></style>
