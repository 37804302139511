<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="label"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :options="localOptions"
  />
  <FormulateInput
    v-else
    ref="select"
    v-model="content"
    type="select-searchable"
    :label="label"
    :placeholder="$t('Digite para pesquisar')"
    :options="localOptions"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    :instruction="$t('Pesquise pela cidade')"
    @fetchSearch="onSearch"
    @input="handleInput"
  />
  <!-- :reduce="(item) => item.idIbge" -->
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Pesquisar')
      },
    },
    valueIsObject: {
      type: Boolean,
      default: false,
    },
    // Opções padrões (caso o localOptions esteja vazio)
    defaultOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      content: null,
      options: [],
    }
  },

  computed: {
    localOptions() {
      const options = this.options.length > 0 ? this.options : this.defaultOptions
      if (this.valueIsObject) {
        return options.map(c => ({
          ...c,
          id: c.idIbge,
          label: this.formatLabel(c),
          value: c,
        }))
      }

      return options.map(c => ({
        ...c,
        id: c.idIbge,
        label: this.formatLabel(c),
        value: c.idIbge,
      }))
    },
  },

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    // necessário para preencher o input com base no valor que vem da prop "value"
    this.content = this.value
  },

  methods: {
    async onSearch(loading, searchString) {
      try {
        loading(true)

        const { data } = await this.$http.get('/api/cities', {
          params: { searchString },
        })

        this.options = data.results || []
      } catch (error) {
        this.showError({ error })
      } finally {
        loading(false)
      }
    },

    formatLabel(data) {
      return `${data.name} - ${data.state?.abreviation || ''}`
    },

    handleInput() {
      this.$emit('input', this.content)
    },

    reset() {
      this.options = []
    },
  },
}
</script>
