var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "text-right",
              staticStyle: { "margin-top": "30px" },
              attrs: { md: "3" },
            },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "day-label",
                  type: "label",
                  name: "hour_" + _vm.dayName + "Label",
                  value: _vm.$t("days." + _vm.dayName),
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: _vm.dayName + "-opening-time",
                  name: "hour_" + _vm.dayName + "OpeningTime",
                  type: "text-mask",
                  mask: ["##:##"],
                  label: _vm.$t("Abre"),
                },
                model: {
                  value: _vm.day.openingTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.day, "openingTime", $$v)
                  },
                  expression: "day.openingTime",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: _vm.dayName + "-closing-time",
                  name: "hour_" + _vm.dayName + "ClosingTime",
                  type: "text-mask",
                  mask: ["##:##"],
                  label: _vm.$t("Fecha"),
                },
                model: {
                  value: _vm.day.closingTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.day, "closingTime", $$v)
                  },
                  expression: "day.closingTime",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  type: "switch",
                  name: "hour_" + _vm.dayName + "Delivery",
                  label: _vm.$t("Delivery"),
                },
                model: {
                  value: _vm.day.delivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.day, "delivery", $$v)
                  },
                  expression: "day.delivery",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  type: "switch",
                  name: "hour_" + _vm.dayName + "ZeroRate",
                  label: _vm.$t("Taxa Zero"),
                },
                model: {
                  value: _vm.day.zeroRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.day, "zeroRate", $$v)
                  },
                  expression: "day.zeroRate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }