<template>
  <div>
    <e-sidebar
      :title="$t('Adcionar cidade')"
      :show="showSidebar"
      :fetching="zipCodeLoading"
      :saving="zipCodeLoading"
      width="500px"
      @save="addLocation"
      @hidden="hideSidebar"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="deliveryLocationForm"
            name="form"
          >
            <e-city-search
              id="delivery-location-city"
              v-model="cityData"
              name="locationCity"
              :label="$t('Cidade')"
              required
              value-is-object
            />
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar } from '@/views/components'
import { stringUtils, uploader } from '@/mixins'
import ECitySearch from '@/views/components/inputs/ECitySearch.vue'

const getInitialLocalLocation = () => ({
  id: null,
  localId: null,
  city: '',
  cityCode: null,
  province: '',
  provinceCode: null,
})

export default {
  components: {
    ESidebar,
    ECitySearch,
  },

  mixins: [uploader, stringUtils],

  props: {},

  data() {
    return {
      showSidebar: false,
      zipCodeLoading: false,
      edit: false,
      saving: false,
      localForm: getInitialLocalLocation(),
      cityData: null,
    }
  },

  watch: {},

  methods: {
    showCreate() {
      this.localForm = getInitialLocalLocation()
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (item) this.localForm = item
        this.edit = true
        this.showSidebar = true
      }
    },

    hideSidebar() {
      this.localForm = getInitialLocalLocation()
      this.cityData = null
      if (this.$refs.deliveryLocationForm) {
        this.$refs.deliveryLocationForm.hideErrors()
      }
      this.showSidebar = false
    },

    async addLocation() {
      this.$refs.deliveryLocationForm.showErrors()
      if (this.$refs.deliveryLocationForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      const { id, localId } = this.localForm
      const payload = {
        id,
        localId,
        city: this.cityData.name,
        cityCode: this.cityData.idIbge,
        province: this.cityData.state.abreviation,
        provinceCode: this.cityData.state.idIbge,
      }

      this.$emit('add-item', payload)
    },
  },
}
</script>

<style></style>
